<template>
    <footer class="page-footer">
        <div class="logo">
            <img id="logo-image" src="../asset/logo.svg" />
        </div>
        <div class="address">
            <address>
                <ul>
                    <li>Musikmuseet</li>
                    <li>Rosenørns allé 22</li>
                    <li>DK-1970 Frederiksberg C</li>
                    <li>Telefon +45 3313 4411</li>
                    <li><a href="https://natmus.dk/museer-og-slotte/musikmuseet/">Musikmuseets hjemmeside</a></li>
                </ul>
            </address>
        </div>
        <div class="short-about">
            Dansk Orgel Index er sponsoreret af Frobeniusfonden<br/>
            <a href="https://frobeniusfonden.dk/">frobeniusfonden.dk</a>
        </div>
    </footer>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class PageFooter extends Vue {
}
</script>

<style>

footer.page-footer {
    display: flex;
    margin: 3em 2em 2em 2em;
    border-top: 1px solid #979797;
    padding-top: 2em;
    padding-bottom: 2em;
    line-height: 1.6em;
}

footer.page-footer .logo {
    flex-grow: 0; 
    flex-shrink: 0;
    flex-basis: 150px;
    padding-left: 2em;
}

footer.page-footer .logo img#logo-image {
    width: 45px;
}

footer.page-footer .address address ul {
    list-style-type: none;
    margin: 0 4em 0 0;
    padding: 0;
    font-style: normal;
}

footer.page-footer .address address ul li {
    margin: 0;
    padding: 0;
}

</style>