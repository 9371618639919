<template>
	<section class="condensed">
		<h1>Om Orgelregistranten</h1>

		<h2>Ekspedition</h2>

		<p>
			Orgelregistrantens materiale kan ses på Musikmuseets læsesal, der har åbent efter aftale. 
			Noget materiale kan også sendes pr. e-mail. Kontakt venligst museumsinspektør Marie Martens 
			på e-mail <a href="mailto:marie.martens@natmus.dk">marie.martens@natmus.dk</a>.
		</p>

		<p>
			Det er normalt gratis at rekvirere oplysninger fra registranten, men der tages betaling for
			affotograferinger/scanninger, som skal udføres af Nationalmuseets fotografer.
		</p>

		<h2>Orgelregistrantens historie</h2>

		<p>
			Den Danske Orgelregistrant er oprettet på initiativ af Det Danske Orgelselskab,
			Nationalmuseet og Musikhistorisk Museum (siden 2006 Musikmuseet). I årene 1972-76 blev
			alle danske pibeorgler undersøgt, fotograferet og beskrevet, og det er dette materiale,
			der udgør grundstammen i orgelregistrantens arkiv.
		</p>

		<h2>Litteratur om Orgelregistranten</h2>

		<ul class="literature-references">
			<li>Ole Olesen: "The Danish Organ Registry", i: The Organ Yearbook, vol. XI, 1980, s. 17-30.</li>
			<li>Ole Olesen: "Das dänische Orgelinventar", i: Ars Organi, Jg. 28, 1980, s. 18-21.</li>
			<li>Anne Ørbæk Jensen, Ole Olesen og Lisbet Torp: "Orgelregistranten – en brugervejledning", i: Dansk Orgelkultur. Det Danske Orgelselskab 1970-1995, [København 1997], s. 307-319.</li>
		</ul>

	</section>
</template>

<script>
export default {
	name: "AboutIndex"
}
</script>
