import "vue-easytable/libs/theme-default/index.css";

import Vue from "vue";
import VueRouter from "vue-router";
import VueEasyTable from "vue-easytable";

Vue.use(VueRouter);
Vue.use(VueEasyTable);

Vue.config.productionTip = false;

import App from "./App.vue";
import Index from "./view/Index.vue";
import Guide from "./view/Guide.vue";
import About from "./view/AboutIndex.vue";

const router = new VueRouter({
	mode: "history",
	base: __dirname,
	routes: [
		{ name: "index", path: "/", component: Index },
		{ name: "guide", path: "/vejledning", component: Guide },
		{ name: "about", path: "/orgelregistranten", component: About },
	]
});

new Vue({
	router,
	render: h => h(App),
}).$mount("#app");
