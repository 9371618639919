<template>
	<div>
		<nav>
			<ul>
				<li class="head">
					<router-link :to="{name: 'index'}">
						<h2>Dansk Orgel Index</h2>
					</router-link>
					<img 
						src="../asset/stylized-name.png"
						style="width: 220px; margin-top: 1em"
					/>
				</li>
				
				<li><router-link :to="{name: 'guide'}">Vejledning</router-link></li>
				<li><router-link :to="{name: 'about'}">Om Orgelregistranten</router-link></li>
				<li class="tail">
					<a href="https://frobeniusfonden.dk/" target="_blank">
						<img alt="Frobeniusfonden" width="250" src="../asset/logo-frobeniusfonden.jpg" />
					</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Navigation extends Vue {
}
</script>

<style scoped>
nav {
	text-align: center;
	margin-top: 3em;
	margin-bottom: 3em;
}

nav a {
	text-decoration: none;
	color: black;
}

nav a:hover {
	text-decoration: underline;
}

nav h2 {
	margin: auto 0 auto 0;
	font-size: 2rem;
}

nav h3,
nav ul {
	text-align: start;
}

nav ul {
	display: flex;
	justify-content: space-between;
	width: 100%;
	list-style-type: none;
	padding: 0;
}

nav li.head {
	display: inline-block;
	width: 400px;
}

nav li {
	display: block;
	flex-grow: 1;
	margin: auto 0 auto 0;
	font-size: 1.2rem;
	font-weight: 300;
}

nav li.tail {
	text-align: right;
}

@media only screen and (max-width: 1280px) {
	nav li {
		font-size: 1rem;
	}

	nav li.head {
		width: auto;
	}
}

</style>
