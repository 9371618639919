/* eslint-disable no-unused-vars */
export class Dataset {
	static sorter = {
		text: property => {
			return (a, b) => a[property].localeCompare(b[property], "da");
		},
		number: property => {
			return (a, b) => Dataset.sorter.numeric(a[property], b[property])
		},
		numeric: (a, b) => Number(a) - Number(b)
	}

	static unique(items, property) {
		return items
			// Find items with unique entries for the given thesaurus
			.filter((subject, index) => items.findIndex(item => item[property] === subject[property]) === index)
			// Extract the values from the items
			.map(item => item[property])
			// Sort them for good measure
			.sort();
	}
}
