<template>
	<section class="condensed">
		<h1>
			Vejledning til Dansk Orgel Index
			<small>- en fortegnelse over orglerne i Danmark</small>
		</h1>

		<p>
			Dansk Orgel Index er grundlagt 2004 takket være en bevilling
			fra FROBENIUS-FONDEN og foreligger pr. 2021 i en ny version
			støttet af FROBENIUS-FONDEN. 
		</p>

		<p>NB: Siden er under udvikling.</p>

		<p>
			Formålet med indexet er at skabe et enkelt og overskueligt
			overblik over Danmarks offentligt tilgængelige pibeorgler*)
			For hvert enkelt instruments vedkommende anføres følgende oplysninger:<br>
		</p>

		<ol>
			<li>Bygningsår**)</li>
			<li>Orgelbygger***)</li>
			<li>Lokalitet</li>
			<li>Amt****)</li>
			<li>Antal stemmer</li>
			<li>Antal manualer</li>
			<li>Pedaloplysninger</li>
		</ol>

		<p>
			Desuden vil man i mange tilfælde også kunne finde oplysninger om
			orglets arkitekt, om der i orglet indgår ældre dele, om orglet er
			ombygget, repareret, flyttet samt oplysninger om bevarede dele.
			Hvor Orgelregistranten ikke har oplysninger, vil dette fremgå
			som ”Ingen data i Orgelregistranten”.
		</p>

		<p>
			Nærmere oplysninger vedr. de enkelte instrumenter kan rekvireres
			via e-mail til museumsinspektør Marie Martens på e-mail
			<a href="mailto:marie.martens@natmus.dk">marie.martens@natmus.dk</a>
		</p>

		<p>
			Dansk Orgel Index opdateres hvert efterår af Den Danske Orgelregistrant.
			Brugerne af Dansk Orgel Index opfordres til at indberette fejl og
			mangler via e-mail til museumsinspektør Marie Martens på e-mail
			<a href="mailto:marie.martens@natmus.dk">marie.martens@natmus.dk</a>
		</p>

		<p>
			<strong>*)</strong> Det samlede antal pibeorgler i landet er således
			noget større, idet følgende instrumenter normalt ikke er medtaget:
		</p>

		<ol>
			<li>Orgler i privateje.</li>
			<li>Ukomplette og ikke-spilbare orgler*****)</li>
			<li>Orgler i Grønland og på Færøerne</li>
		</ol>

		<p>
			<strong>**)</strong> I de tilfælde, hvor bygningsåret er usikkert,
			vil dette fremgå. Eventuel usikkerhed omkring bygmester, størrelse
			eller lokalitet er derimod ikke markeret i indexet.
		</p>

		<p>
			<strong>***)</strong> Bemærk, at et firma kan optræde under
			skiftende firmanavne. For eksempel optræder det firma, der i dag
			hedder P.G. Andersen &amp; Bruhn under følgende firmanavne:
			1) Brdr. Bruhn, 2) P. Bruhn, 3) P. Bruhn &amp; Søn,
			4) P.G. Andersen &amp; Bruhn og 5) Poul-Gerhard Andersens
			Orgelbyggeri.
		</p>

		<p>
			<strong>****)</strong> 1) Bornholms Amt, 2) Frederiksborg Amt,
			3) Fyns Amt, 4) København, 5) Københavns Amt, 6) Nordjyllands Amt,
			7) Ribe Amt, 8) Ringkøbing Amt, 9) Roskilde Amt, 10) Storstrøms Amt,
			11) Sønderjyllands Amt, 12) Vejle Amt, 13) Vestsjællands Amt,
			14) Viborg-Thisted Amt, 15) Aarhus Amt.
		</p>
		
		<p>
			<strong>*****)</strong> I enkelte tilfælde, hvor ikke-spilbare
			orgler påkalder sig særlig interesse, kan disse være medtaget i
			indexet.
		</p>
	</section>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Guide extends Vue {}
</script>