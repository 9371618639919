<template>
	<section>
		<header>
			<div>
				<h3>En fortegnelse over orglerne i Danmark</h3>

				<p>
					Indeholder information om {{ table.items.length.toLocaleString() }} orgler.
					<router-link :to="{name: 'guide'}">Klik her for vejledning.</router-link>
				</p>
			</div>
		</header>

		<div class="search-group">
			<input class="text-field search-field" type="text" v-model="search" @keyup.enter="performSearch()">
			<button class="button button-ok" @click="performSearch()">Søg</button>
			<button class="button button-clear" @click="clearSearch()" :disabled="!activeSearch">Ryd søgning</button>
		</div>

		<div class="result-count-group">
			Viser {{ filteredResult.count.toLocaleString() }} resultater<span v-if="isSearching"> (søger på "{{activeSearch}}")</span>.
			&nbsp;<a :href="downloadUrl" download>Klik her for at hente en Excel fil med al data</a>.
		</div>

		<ve-table
			id="data-table"
			:columns="table.columns"
			:table-data="filteredResult.items"
			:sort-option="sortOption"
			:virtual-scroll-option="{enable: true}"
			:cell-span-option="cellSpanOption"
			:cell-style-option="cellStyleOption"
			:expand-option="expandOption"
			:cellSelectionOption="cellSelectionOption"
			max-height="1000px"
			row-key-field-name="_key"

		/>
	</section>
</template>

<style scoped>

.search-group {
	margin-top: 2em;
	display: flex;
}

.text-field-label {
	display: block;
	font-weight: bold;
	padding: 0 0 0.5em 0;
}

.text-field {
	padding: 0.25em;
	font-size: large;
	width: 25em;
}

.button {
	margin: 0 0 0 0.5em;
	padding: 0.25em;
}

.button.button-ok {
	background-color: #3a4a62;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	color: white;
	padding: 2px 3em;
	border: none;
}

button.button-clear {
	border: none;
	color: black;
	background: transparent;
	padding: 0 1.5em;
}

button.button-clear:disabled {
	color: #ccc;
}

.result-count-group {
	margin-top: 2em;
	margin-bottom: 2em;
	margin-left: 1.2em;
	font-weight: bold;
}

.text-field.search-field {
	border: 1px solid #ccc;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	padding: 0.25em 1em;
	font-size: 1.2rem;
}

section {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

section > #data-table {
	/* minimal width to fit all column names without lines breaking */
	min-width: 1062px;
	flex-grow: 1;
}
section > #data-table /deep/ .ve-table-header-th {
	background-color: #EFEFEF;
}
section > #data-table /deep/ .ve-table-header-th.header-year {
	background-color: #F3F0E0;
}
section > #data-table /deep/ .ve-table-header-th.header-placement {
	background-color: #E4EADE;
}
section > #data-table /deep/ .ve-table-header-th.header-construction {
	background-color: #DCE5E6;
}
section > #data-table /deep/ .ve-table-header-th.header-size {
	background-color: #DEDEE8;
}
section > #data-table /deep/ .ve-table-header-th.header-pedal {
	background-color: #EDE8EA;
}
section > #data-table /deep/ .ve-table-sort {
	margin: -4px 0 3px 5px;
}
section > #data-table /deep/ .ve-table-filter {
	margin: 0 0 -2px -6px;
}
section > #data-table /deep/ .ve-table-cell-selection {
	border: 0;
}
section > #data-table /deep/ dt {
	font-weight: bold;
}
section > #data-table /deep/ dd {
	margin-inline-start: 30px;
}

</style>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import {Column} from "@/helper/column";

const LABEL_NO_DATA = "Ingen data i Orgelregistranten.";

@Component
class Index extends Vue {

	search = '';
	activeSearch = '';

	table = {
		columns: [],
		items: []
	};

	thesaurus = {
		builder: [],
		county: [],
		pedal: []
	};

	get enableSearchButton() {
		return this.activeSearch != this.search;
	}

	get isSearching() {
		return this.activeSearch != null && this.activeSearch != undefined && this.activeSearch.trim() != "";
	}

	splitStringWithQuotes(str) {
		// Trim leading and trailing spaces
		const trimmedStr = str.trim();

		// Regular expression to match quoted text or sequences of non-space characters
		const regex = /"([^"]*)"|\S+/g;

		// Array to hold the matches
		let matches = [];
		let match;

		// Use the regex to find all matches in the string
		while ((match = regex.exec(trimmedStr)) !== null) {
			// If the match is quoted text, push the captured group (without quotes)
			// Otherwise, push the entire match (unquoted text)
			matches.push(match[1] ? match[1] : match[0]);
		}

		return matches;
	}
	
	escapeRegExpInput(input) {
		console.log(input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
		return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
	}

	get filteredResult() {
		if (!this.isSearching) {
			return { 
				count: this.table.items.length, 
				items: this.table.items 
			};
		}

		var result = { count: 0, items: [] };

		result.items = this.table.items.filter(item => 
		{
			var properties = [
				item.architect,
				item.builder,
				item.county,
				item.disposition,
				item.former_instruments, 
				item.location, 
				(item.manuals && item.manuals.toString()), 
				item.modifications,
				item.older_parts,
				(item.pedal && item.pedal.toString()),
				item.pedalLabel,
				item.preserved_parts,
				(item.voices && item.voices.toString()),
				(item.year && item.year.toString()),
				item.transmissions
			]
				.filter(x => x != null && x != undefined);
			
			var propertiesJoinedString = properties.join(" ");
			var splittedActiveSearch = this.splitStringWithQuotes(this.activeSearch);
			var hasMatch = true;
			splittedActiveSearch.forEach(activeSearchItem => {
				var rgx = new RegExp(this.escapeRegExpInput(activeSearchItem), "i");
				if(propertiesJoinedString.match(rgx) == null) {
					hasMatch = false; 
				}
			});

			return hasMatch;
		});

		result.count = result.items.length;

		return result;
	}

	created() {
		// Put a loader on the table
		this.loadingInstance = this.$veLoading({
			target: "#data-table",
			name: "wave",
			tip: "indlæser..."
		});
	}

	async mounted() {
		// Put a loader on the table
		this.loadingInstance.show();

		await this.loadThesauri();

		this.table.columns = await this.loadTableColumns();

		this.table.items = await this.loadTableItems();

		// Remove the loader from the table
		this.loadingInstance.close();
	}

	beforeDestroy() {
		this.loadingInstance.destroy();
	}

	async loadThesauri() {
		this.thesaurus.builder = await this.loadDataArray("builder");
		this.thesaurus.county = await this.loadDataArray("county");
		this.thesaurus.pedal = await this.loadDataArray("pedal");
	}

	async loadTableColumns() {
		// Load the column definitions from file
		let columns = await this.loadDataArray("table-column");

		// Map in any methods for the columns
		columns = columns.map(column => ({
			...column,
			title: column.label || column.title,
			...Column.methods[column.field]
		}));

		// Transform the column definitions to the actual table header
		return Column.setup.map(column => {
			if(!column.children) {
				return column;
			}

			column.children = column.children.map(child => {
				if(child === "expand") {
					return {
						class: column.class,
						field: "",
						key: "0",
						type: "expand",
						title: "",
						width: 40,
						align: "center"
					};
				}

				return {
					class: column.class,
					...columns.filter(subject => subject.field === child)[0]
				};
			});

			return column;
		});
	}

	async loadTableItems() {
		let items = await this.loadDataArray("table-row");

		// Amend the dataset with some presentational field representations
		//  and a row indexing key for better performance in the table
		return items.map((item, index) => ({
			...item,

			// Populate values from thesauri
			builder: this.thesaurus.builder[item.builder],
			county: this.thesaurus.county[item.county],
			// Keep the pedal value and add the substitution so sorting can be numerical
			//pedal: this.thesaurus.pedal[item.pedal],
			pedalLabel: this.thesaurus.pedal[item.pedal],

			_key: index
		}));
	}

	changeSorting(params) {
		// get only the first columns with sort directions
		let field = Object.keys(params).filter(key => params[key] !== "")[0];

		// get the column corresponding to the sorting field
		let column = this.table.columns
			.reduce((columns, value) => columns.concat(value.children ? value.children : value), [])
			.filter(column => column.field === field)[0];

		if(!column || !column.field) {
			return;
		}

		if(params[field] === "asc") {
			this.table.items.sort(column.sorter);
		}
		else if(params[field] === "desc") {
			this.table.items.sort((a, b) => column.sorter(b, a));
		}

	}

	performSearch() {
		var r = this.search.toString();
		this.activeSearch = r;
	}

	clearSearch() {
		this.search = '';
		this.activeSearch = '';
	}

	loadingInstance = null;
	sortOption = {
		sortAlways: true,
		sortChange: this.changeSorting
	}

	cellSpanOption = {
		bodyCellSpan: ({column}) => {
			if(["location"].indexOf(column.field) !== -1) {
				return {
					rowspan: 1,
					colspan: 2,
				};
			}

			// does not need to be rendered
			if(["year_unconfirmed", "county"].indexOf(column.field) !== -1) {
				return {
					rowspan: 0,
					colspan: 0,
				};
			}
		}
	}

	cellStyleOption = {
		headerCellClass: ({column}) => {
			if(column.class) {
				return column.class;
			}
		}
	}

	cellSelectionOption = {
		// disble cell selection
		enable: false,
	}

	expandOption = {
		/* eslint-disable no-unused-vars */
		render: ({row}, h) => {
			return (
				<dl>
					<dt>Ombygn., rep. m.v.</dt>
					<dd>{row.modifications ? row.modifications : LABEL_NO_DATA}</dd>
					<dt>Ældre dele</dt>
					<dd>{row.older_parts ? row.older_parts : LABEL_NO_DATA}</dd>
					<dt>Bevarede dele</dt>
					<dd>{row.preserved_parts ? row.preserved_parts : LABEL_NO_DATA}</dd>
				</dl>
			);
		},
	}

	async loadDataArray(endpoint) {
		// Get the data
		let response = await fetch(`/data/output/${endpoint}.json?random=` + Math.random());

		// Handle data errors
		if(response.ok) {
			// get the response body (the method explained below)
			return response.json();
		}

		console.error("HTTP-Error: " + response.status);
		return [];
	}

	downloadUrl = "/data/output/orgler.xlsx";
}

export default Index;
</script>
