<template>
	<div id="app">
		<navigation />

		<router-view />

		<page-footer />
	</div>
</template>

<script>
import Navigation from "./component/navigation.vue"
import PageFooter from "./component/page-footer.vue"

export default {
	name: "App",
	components: {
		Navigation,
		PageFooter
	}
}
</script>

<!--
/*
bgcolor="#FFCC66"
text="#000000"
link="#9D0000"
vlink="#004000"
alink="#0000A0"

#0000A0 ORGELREGISTRANTEN
*/
-->

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@100;200;300;400;700&display=swap');

body, button, input, select, textarea {
	/* Force system UI fonts:
		- starting with special keywords,
		- then font names and most basic fallbacks
		- lastly symbol fonts to properly enable emoji
	*/
	font-family: Ubuntu,	 -apple-system, system-ui, BlinkMacSystemFont,
	"Segoe UI", Roboto, Oxygen-Sans, "Oxygen", Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif,
	EmojiFont, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols !important;

	line-height: 1.25em;
	font-variant-numeric: lining-nums tabular-nums;
	font-variant-ligatures: no-common-ligatures;
	font-feature-settings: "vpal";

	text-rendering: geometricPrecision;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: subpixel-antialiased;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	/* Adjust the fonts to use the same lowercase letter height, on supporting browsers */
	font-size-adjust: 0.5;
}

html, body, #app {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
}

body {
	background-color: #fffcf8;
}

#app {
	padding: 0;
	margin: 0;
}

#app section {
	flex-grow: 1;
}

#app nav, #app section {
	margin-left: 2em;
	margin-right: 2em;
}

#app section.condensed {
	max-width: 800px;
	margin-top: 5em;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 2em;
}

#app section h1 {
	line-height: 1.8rem;
}

#app section h1 small {
	display: block;
	font-size: 1rem;
}
</style>
