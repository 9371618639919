/* eslint-disable no-unused-vars */
import {Dataset} from "@/helper/dataset";

export class Column {
	static get setup() {
		return [
			{
				class: "header-expand",
				title: "",
				children: ["expand"]
			},
			{
				class: "header-year",
				title: "Bygningsår",
				children: [/*"expand",*/ "year" /*, "year_unconfirmed"*/ ]
			},
			{
				class: "header-placement",
				title: "Placering",
				children: ["location", "county"]
			},
			{
				class: "header-construction",
				title: "Konstruktion",
				children: ["builder", "architect"]
			},
			{
				class: "header-size",
				title: "Størrelse",
				children: ["voices", "transmissions", "manuals"]
			},
			{
				class: "header-pedal",
				title: "",
				children: ["pedal"]
			}
		];
	}

	static methods = {
		year: {
			renderBodyCell: ({row}) => {
				let value = row.year == null ? "" : row.year + (row.year_unconfirmed ? " (?)" : "");

				return value;
			},
			sorter: Dataset.sorter.number("year")
		},
		// year_unconfirmed: {
		// 	sorter: Dataset.sorter.number("year_unconfirmed")
		// 	//sorter: (a, b) => this.sorter.number("year_unconfirmed")(a, b) || this.sorter.number("year")(a, b)
		// },
		location: {
			renderBodyCell: ({row}) => row.location + "\n" + row.county,
			//renderBodyCell: ({row}, h) => h([row.location, h("br"), row.county]),
			sorter: Dataset.sorter.text("location")
		},
		county: {
			sorter: Dataset.sorter.text("county")
		},
		builder: {
			sorter: Dataset.sorter.text("builder")
		},
		architect: {
			sorter: Dataset.sorter.text("architect")
		},
		voices: {
			renderBodyCell: ({row, column}) => row[column.field] === null ? "—tom—" : row[column.field],
			sorter: (a, b) => Dataset.sorter.number("voices")(a, b) || Dataset.sorter.number("manuals")(a, b)
		},
		manuals: {
			renderBodyCell: ({row, column}) => row[column.field] === null ? "—tom—" : row[column.field],
			sorter: (a, b) => Dataset.sorter.number("manuals")(a, b) || Dataset.sorter.number("voices")(a, b),
		},
		pedal: {
			renderBodyCell: ({row, column}) => row.pedalLabel,
			sorter: Dataset.sorter.number("pedal")
		},
		disposition: {
			sorter: Dataset.sorter.text("disposition")
		},
		modifications: {
			sorter: Dataset.sorter.text("modifications")
		},
		former_instruments: {
			sorter: Dataset.sorter.text("former_instruments")
		},
		older_parts: {
			sorter: Dataset.sorter.text("older_parts")
		},
		preserved_parts: {
			sorter: Dataset.sorter.text("preserved_parts")
		},
		transmissions: {
			sorter: Dataset.sorter.text("transmissions")
		}
	}
}
